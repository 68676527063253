import { Controller } from '@hotwired/stimulus'

export default class DropdownController extends Controller {
  static targets = ['menu'];

  toggle () {
    this.menuTargets.forEach((menu) => {
      menu.classList.add('hidden')
    })
  }
}
