import { Controller } from '@hotwired/stimulus'
import TomSelect from 'tom-select'

export default class ToplightControlsController extends Controller {
  async connect () {
    this.initializeTomSelects()
    this.setupInputStyles('location_id-ts-control')
    this.setupInputStyles('toplight_color-ts-control')

    const element = document.getElementByClass('ts-control')
    if (element) {
      if (window.innerWidth < 1024) {
        element.classList.add('h-[4rem]', '!text-4xl')
      }
    }
  }

  initializeTomSelects () {
    document.querySelectorAll('select').forEach((elem) => {
      this.tomSelect = new TomSelect(`#${elem.id}`, {
        create: false,
        sortField: {
          field: 'text',
          direction: 'asc'
        },
        render: {
          option: this.renderHandler('option'),
          item: this.renderHandler('item'),
          no_results: this.renderHandler('no_results', 'No Results Found')
        }
      })
    })
  }

  renderHandler (type, noResultsText = null) {
    return data => {
      const div = document.createElement('div')
      div.className = 'flex align-items-center'

      const span = document.createElement('span')
      span.className = 'flex-grow-1 text-4xl lg:text-base p-4 lg:p-1'
      span.innerText = type === 'no_results' ? noResultsText : data.text

      div.append(span)
      return div
    }
  }

  setupInputStyles (inputId) {
    const element = document.getElementById(inputId)
    if (element) {
      if (window.innerWidth < 1024) {
        element.classList.add('h-[4rem]', '!text-4xl')
      }
    }
  }
}
