import { Controller } from '@hotwired/stimulus'

export default class FieldQaController extends Controller {
  static targets = ['modal', 'form', 'loader', 'content', 'scanner']

  connect () {
    this.timer = setTimeout(() => {
      if (this.hasFormTarget) {
        this.formTarget.requestSubmit()
      }
    }, 1000)
  }

  disconnect () {
    if (this.timer) {
      clearTimeout(this.timer)
      this.timer = null
    }
  }

  open () {
    this.modalTarget.classList.remove('hidden')
  }

  close () {
    this.modalTarget.classList.add('hidden')
  }

  showSpinLoader (e) {
    if (this.hasScannerTarget && !this.scannerTarget.checkValidity()) {
      return
    }

    if (this.hasLoaderTarget) {
      this.loaderTarget.classList.remove('hidden')
      this.contentTarget.classList.add('hidden')
    }
  }
}
