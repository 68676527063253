import { Controller } from '@hotwired/stimulus'
import FlashController from './flash_controller'

export default class VconnAssemblySetupController extends Controller {
  static values = { currentStep: Number }
  static targets = [
    'form',
    'stepContent',
    'stepLoader',
    'prevButton',
    'nextButton'
  ];

  showLoader (e) {
    this.stepContentTarget.classList.add('hidden')
    this.stepLoaderTarget.classList.remove('hidden')
    this.prevButtonTarget.disabled = true
    this.nextButtonTarget.disabled = true
    this.nextButtonTarget.classList.add('!text-gray-400')

    if (e && e.currentTarget) {
      const form = e.currentTarget.closest('form')
      form.requestSubmit()
    }
  }

  submitForm (e) {
    if (!this.hasFormTarget) {
      return
    }

    const isNext = e.currentTarget.dataset.isNext === 'true'
    if (isNext) {
      if (this.formTarget.checkValidity()) {
        const formAction = this.formTarget.action
        const url = new URL(formAction)
        const params = new URLSearchParams(url.search)
        const currentStepKey = params.get('current_step_key')

        if (currentStepKey === 'verify_vconn_data') {
          FlashController.prototype.renderFlash('Syncing Data to GoCodes, it may take some time.', 'success')
        }

        this.showLoader()
        this.formTarget.requestSubmit()
      } else {
        this.formTarget.reportValidity()
      }
    } else {
      this.formTarget.noValidate = true
      const extraInput = document.createElement('input')
      extraInput.type = 'hidden'
      extraInput.name = 'nav_back'
      extraInput.value = true
      this.formTarget.appendChild(extraInput)

      this.showLoader()
      this.formTarget.requestSubmit()
    }
  }
}
