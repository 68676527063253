import { Controller } from '@hotwired/stimulus'

export default class RedirectQrSupportController extends Controller {
  static targets = ['modal', 'form'];

  open (e) {
    this.hasModalTarget && this.modalTarget.classList.remove('hidden')
  }

  submitForm (e) {
    if (this.formTarget.checkValidity()) {
      this.formTarget.requestSubmit()
      this.close()
    } else {
      this.formTarget.reportValidity()
    }
  }

  close (e) {
    this.hasModalTarget && this.modalTarget.classList.add('hidden')
  }
}
