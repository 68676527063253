import { Controller } from '@hotwired/stimulus'
class SearchFormController extends Controller {
  connect () { }

  submit (e) {
    e.preventDefault()
    const params = new URLSearchParams(window.location.search)
    this.element.querySelectorAll('input').forEach(el => {
      params.set(el.name, el.value)
    })
    window.location.search = params
  }
}

class ToggleController extends Controller {
  static values = {
    default: { type: Boolean, default: false }
  }

  connect () {
    this.init()
  }

  toggleControl (el) {
    this.setParam(el.target.name, el.target.checked)
    this.element.value = el.target.checked
  }

  setParam (param, value) {
    const url = new URL(window.location.href)
    url.searchParams.set(param, value)
    this.element.value = value
    window.history.pushState(null, '', url.toString())
  }

  init () {
    const params = new URLSearchParams(window.location.search)
    if (params.has(this.element.name)) {
      this.element.checked = params.get(this.element.name) === 'true'
      this.element.value = params.get(this.element.name) === 'true'
    } else {
      this.element.checked = this.defaultValue
      this.element.value = this.defaultValue
    }
  }
}

class TextSearchController extends Controller {
  connect () {
    this.init()
  }

  init () {
    const params = new URLSearchParams(window.location.search)
    if (params.has(this.element.name)) { this.element.value = params.get(this.element.name) }
  }
}

export { ToggleController, TextSearchController, SearchFormController }
