import { Controller } from '@hotwired/stimulus'

const VALID_EXTENSIONS = ['pdf', 'csv', 'xlsx', 'xls']
const ERROR_MESSAGES = {
  count: 'Can not select multiple files',
  extension: 'File type must be pdf, csv, xls, or xlsx'
}

export default class DragDropController extends Controller {
  static targets = [
    'fileField',
    'fileDetailContainer',
    'errorContainer'
  ]

  allowDrop (e) {
    e.preventDefault()
  }

  browseFile () {
    this.errorContainerTarget.innerText = ''
    this.fileFieldTarget.click()
  }

  handleFileDropped (e) {
    e.preventDefault()
    this.errorContainerTarget.innerText = ''

    const files = e.dataTransfer.files
    if (this._checkFile(files)) {
      this.fileFieldTarget.files = this._addFileList(files)
      this.fileDetailContainerTarget.innerText = this.fileFieldTarget.files[0].name
    }
  }

  _checkFile (files) {
    return (this._checkFilesCount(files) && this._checkFileExtension(files))
  }

  _checkFilesCount (files) {
    const check = files.length === 1
    if (!check) { this.errorContainerTarget.innerText = ERROR_MESSAGES.count }

    return check
  }

  _checkFileExtension (files) {
    const check = VALID_EXTENSIONS.includes(files[0].name.split('.').pop())
    if (!check) { this.errorContainerTarget.innerText = ERROR_MESSAGES.extension }

    return check
  }

  _addFileList (files) {
    const obj = new DataTransfer()
    for (let i = 0, len = files.length; i < len; i++) {
      obj.items.add(files[i])
    }

    return obj.files
  }
}
