import { Controller } from '@hotwired/stimulus'

export default class DriveIntegrationController extends Controller {
  static targets = ['content', 'overlay']

  showLoader () {
    this.overlayTarget.classList.remove('hidden')
    this.contentTarget.classList.add('hidden')
  }
}
