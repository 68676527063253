import { embedDashboard } from '@superset-ui/embedded-sdk'
import { Controller } from '@hotwired/stimulus'

export default class SupersetDashboardController extends Controller {
  static targets = ['supersetDashboard'];

  connect () {
    fetch('api/v1/superset/maintenance_dashboard')
      .then((response) => response.json())
      .then((data) => {
        const { guestToken, dashboardId, domain } = data

        embedDashboard({
          id: dashboardId,
          supersetDomain: domain,
          mountPoint: this.supersetDashboardTarget,
          fetchGuestToken: () => guestToken,
          dashboardUiConfig: { hideTitle: true }
        })

        const iframe = this.supersetDashboardTarget.querySelector('iframe')

        if (iframe) {
          iframe.style.width = '100%'
          iframe.style.height = '138rem'
        }
      })
      .catch((error) => {
        console.error('Error embeding dashboard:', error)
      })
  }
}
