import { Controller } from '@hotwired/stimulus'

export default class WorkordersController extends Controller {
  static targets = ['modal', 'form'];

  open (e) {
    this.hasModalTarget && this.modalTarget.classList.remove('hidden')
  }

  close (e) {
    if (this.hasModalTarget) {
      this.modalTarget.classList.add('hidden')
    }
  }
}
