import { toZonedTime } from 'date-fns-tz'

const CHART_INFO = {
  asset: {
    media_player_pc: {
      config: {
        axis_name: 'Percentage (%)',
        title: 'Media PC Metrics'
      },
      memory: { name: 'Memory', colorHex: '#36A2EB' },
      cpu: { name: 'CPU', colorHex: '#FFCD56' }
    },
    power_controller_unit: {
      config: {
        axis_name: 'Ampere (A)',
        title: 'Power Controller Metrics'
      },
      iboot_current: { name: 'Current', colorHex: '#36A2EB' }
    }
  },
  location: {
    media_pc_temperature: {
      config: {
        axis_name: 'Temperature (F)',
        title: 'Media Player PC Temperature Chart'
      },
      temperature: { name: 'Media Player PC', colorHex: '#FF6384' }
    },
    gds_display_temperature: {
      config: {
        axis_name: 'Temperature (F)',
        title: 'E-Paper Display Temperature Chart'
      },
      temperature: { name: 'Dual E-Paper Display', colorHex: '#FF6384' }
    },
    power_controller_temperature: {
      config: {
        axis_name: 'Temperature (F)',
        title: 'Power Controller Temperature Chart'
      },
      temperature_probe_1: { name: 'Probe 1 Temperature', colorHex: '#FF6384' },
      temperature_probe_2: { name: 'Probe 2 Temperature', colorHex: '#FFB0C0' }
    },
    power_controller_power_consumption: {
      config: {
        axis_name: 'Watts (W)',
        title: 'Power Controller Power Consumption'
      },
      power_consumption: { name: 'Power Controller Unit', colorHex: '#36A2EB' }
    },
    network_usage: {
      config: {
        axis_name: 'Bandwidth (MB)',
        title: 'Network Usage Chart'
      },
      upload: { name: 'Upload', colorHex: '#36A2EB' },
      download: { name: 'Download', colorHex: '#FFCD56' }
    },
    vconn_kiosk_temperature: {
      config: {
        axis_name: 'Internal Temp (F)',
        title: 'Vconn Kiosk Temperature Chart'
      },
      vconn1_temp: { name: 'Vconn Kiosk 1 Temp', colorHex: '#FF6384' },
      vconn2_temp: { name: 'Vconn Kiosk 2 Temp', colorHex: '#FFB0C0' }
    },
    vconn_kiosk_luminance: {
      config: {
        axis_name: 'External Luminance',
        title: 'Vconn Kiosk External Luminance Chart'
      },
      vconn1_luminance: { name: 'Vconn Kiosk 1 Ext Luminance', colorHex: '#36A2EB' },
      vconn2_luminance: { name: 'Vconn Kiosk 2 Ext Luminance', colorHex: '#FFCD56' }
    }
  }
}

export const chartInfo = (type, chartType, subType) =>
  CHART_INFO[type][chartType][subType]

export const chartConfig = (type, chartType, timeZone) => {
  return {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      intersect: false,
      mode: 'index'
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Time (HH:MM)'
        },
        type: 'time',
        time: {
          unit: 'hour',
          displayFormats: {
            hour: 'HH:mm'
          }
        },
        min: toZonedTime(new Date(Date.now() - 24 * 60 * 60 * 1000), timeZone),
        max: toZonedTime(new Date(), timeZone),
        ticks: {
          stepSize: 1,
          source: 'auto'
        }
      },
      y: {
        title: {
          display: true,
          text: CHART_INFO[type][chartType].config.axis_name
        }
      }
    },
    plugins: {
      legend: {
        position: 'top'
      },
      title: {
        display: true,
        text: `${CHART_INFO[type][chartType].config.title} (24Hrs)`
      },
      tooltip: {
        callbacks: {
          title: (tooltipItems) => {
            const date = new Date(tooltipItems[0].parsed.x)
            const hours = String(date.getHours()).padStart(2, '0')
            const minutes = String(date.getMinutes()).padStart(2, '0')
            return `${hours}:${minutes}`
          }
        }
      }
    }
  }
}
