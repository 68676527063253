import { Controller } from '@hotwired/stimulus'
import FlashController from './flash_controller'

export default class ReplacementController extends Controller {
  static targets = ['form', 'content', 'overlay']

  showLoader () {
    if (this.formTarget.checkValidity()) {
      FlashController.prototype.renderFlash('Syncing Data to GoCodes, it may take some time.', 'success')
      this.overlayTarget.classList.remove('hidden')
      this.contentTarget.classList.add('hidden')
    }
  }
}
