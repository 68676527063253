import { Controller } from '@hotwired/stimulus'
import { Carousel } from 'flowbite'

export default class CarouselController extends Controller {
  static values = {
    containerId: String,
    itemsCount: Number
  };

  connect () {
    this.createCarousel()
  }

  createCarousel () {
    const carouselElement = document.getElementById(this.containerIdValue)
    const instanceOptions = {
      id: this.containerIdValue,
      override: true
    }
    const items = this.createItems()
    const options = this.createOptions()
    this.carousel = new Carousel(carouselElement, items, options, instanceOptions)
  }

  prevSlide () {
    this.carousel.prev()
  }

  nextSlide () {
    this.carousel.next()
  }

  createItems () {
    const items = []

    for (let i = 0; i < this.itemsCountValue; i++) {
      items.push({
        position: i,
        el: document.getElementById(`carousel-item-${i}`)
      })
    }
    return items
  }

  createOptions () {
    const options = {
      defaultPosition: 0,
      interval: 5000,

      indicators: {
        activeClasses: 'bg-primary',
        inactiveClasses:
          'bg-primary-200 hover:bg-primary',
        items: this.createIndicatorItems()
      }
    }
    return options
  }

  createIndicatorItems () {
    const items = []

    for (let i = 0; i < this.itemsCountValue; i++) {
      items.push({
        position: i,
        el: document.getElementById(`carousel-indicator-${i}`)
      })
    }
    return items
  }
}
