import { Controller } from '@hotwired/stimulus'

const SHELTER_SETUP_FORM_DATA_KEY = 'shelterSetupFormData'

export default class ShelterSetupController extends Controller {
  static values = { clearForm: Boolean };
  static targets = ['modal', 'modalCloseButton', 'modalHeader'];

  connect () {
    this.changeHandler = (e) => this.handleInputChange(e)
    this.element.querySelectorAll("input[type='text']").forEach((input) => {
      input.addEventListener('change', this.changeHandler)
    })
    this.setFormData()
  }

  disconnect () {
    this.element.querySelectorAll("input[type='text']").forEach((input) => {
      input.removeEventListener('change', this.changeHandler)
    })
  }

  setFormData () {
    const formData =
      JSON.parse(window.sessionStorage.getItem(SHELTER_SETUP_FORM_DATA_KEY)) || {}
    this.element
      .querySelectorAll("input[type='text']")
      .forEach((input) => {
        if (input.value === null || input.value === '') {
          input.value = formData[input.id] || ''
        }
      })
  }

  handleFormSubmit () {
    window.sessionStorage.removeItem(SHELTER_SETUP_FORM_DATA_KEY)
  }

  handleClearForm () {
    if (
      window.confirm(
        'Are you sure you want to proceed? All data will be lost!!!'
      )
    ) {
      window.sessionStorage.removeItem(SHELTER_SETUP_FORM_DATA_KEY)
      this.setFormData()
    }
  }

  handleInputChange (e) {
    const { id, value } = e.currentTarget
    this.updateSessionStorage(id, value)
  }

  updateSessionStorage (id, value) {
    const formData =
      JSON.parse(window.sessionStorage.getItem(SHELTER_SETUP_FORM_DATA_KEY)) || {}
    formData[id] = value
    window.sessionStorage.setItem(
      SHELTER_SETUP_FORM_DATA_KEY,
      JSON.stringify(formData)
    )
  }
}
