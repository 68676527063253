import selectize from 'selectize' // eslint-disable-line
// Just add the `unfancy` and `fancy-select` classes to a select box and it will be a fancy select box!
const LoadFancySelects = () => {
  $('.unfancy.fancy-select select').selectize({ normalize: true })
  // Remove them so we don't initialize twice
  $('.unfancy.fancy-select').removeClass('unfancy')

  document.addEventListener('turbo:load', function () {
    $('.selectize').selectize()
    // With turbo, selectize placeholder text gets broken
    $('.selectize-control .not-full input').css('width', '100%')
  })
}
export default LoadFancySelects
