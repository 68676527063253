import { Controller } from '@hotwired/stimulus'
import FlashController from './flash_controller'

export default class BroadsignRegistrationController extends Controller {
  showFlash (e) {
    e.preventDefault()
    e.currentTarget.disabled = true
    FlashController.prototype.renderFlash('Broadsign Player Registration in progress, it may take some time.', 'success')
    e.currentTarget.closest('form').submit()
  }
}
