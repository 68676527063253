import { Controller } from '@hotwired/stimulus'
import { chartInfo, chartConfig } from '../utils/chart'
import Chart from 'chart.js/auto'
import 'chartjs-adapter-date-fns'
import { toZonedTime } from 'date-fns-tz'

export default class ChartsController extends Controller {
  static targets = ['chartCanvas', 'chartLoader', 'accordion'];
  static values = {
    onConnect: Boolean,
    chartType: String,
    recordType: String,
    timeZone: String,
    recordId: Number
  };

  async connect () {
    if (this.onConnectValue || (!this.onConnectValue && this.accordionTarget.getAttribute('aria-expanded') === 'true')) {
      this.buildChart()
    }

    if (!this.onConnectValue && this.accordionTarget) {
      const bodies = document.querySelectorAll('[id^="accordion-collapse-body-"]')

      bodies.forEach((body) => {
        body.classList.add('hidden')
        const idNumber = body.id.replace('accordion-collapse-body-', '')
        const heading = document.getElementById(`accordion-collapse-heading-${idNumber}`)

        if (heading) {
          heading.setAttribute('aria-expanded', 'false')
        }
      })
    }
  }

  async buildChart () {
    if (this.chartCanvasTarget.getAttribute('data-chart-created') !== 'true') {
      try {
        const data = await this.fetchApiData()
        if (typeof (data) === 'string') throw data

        const labels = data.labels.map(time => toZonedTime(new Date(time), this.timeZoneValue))
        const datasets = Object.keys(data.values).map((key) =>
          this.buildDataset(key, data.values[key])
        )

        const ctx = this.chartCanvasTarget.getContext('2d')
        this.chart = new Chart(ctx, {
          type: 'line',
          data: {
            labels: labels,
            datasets
          },
          options: chartConfig(this.recordTypeValue, this.chartTypeValue, this.timeZoneValue)
        })

        this.chart.resize(800, 250)
        this.chartLoaderTarget.classList.add('hidden')
        this.chartCanvasTarget.setAttribute('data-chart-created', true)
        return this.chart
      } catch (error) {
        this.updateChartLoader()
        console.error('Error building chart:', error)
      }
    }
  }

  async fetchApiData () {
    try {
      const url =
        this.recordTypeValue === 'location'
          ? `/api/v1/charts/locations/${this.recordIdValue}/${this.chartTypeValue}`
          : `/api/v1/charts/assets/${this.recordIdValue}`

      const response = await fetch(url)
      const data = await response.json()
      return data.success === false ? data.message : data[this.chartTypeValue]
    } catch (error) {
      console.error('Error fetching data:', error)
    } finally {
      this.updateChartLoader()
    }
  }

  buildDataset (type, data) {
    const { name, colorHex } = chartInfo(
      this.recordTypeValue,
      this.chartTypeValue,
      type
    )

    return {
      label: name,
      data,
      borderColor: colorHex,
      backgroundColor: `${colorHex}80`
    }
  }

  updateChartLoader () {
    this.chartLoaderTarget.classList.remove('hidden')
    this.chartLoaderTarget.innerHTML =
      "<p class='text-center'>Error while building the chart</p>"
  }

  disconnect () {
    if (this.chart) {
      this.chart.destroy()
      this.chart = null
    }
    this.chartCanvasTarget.setAttribute('data-chart-created', false)
    this.chartLoaderTarget.classList.remove('hidden')
  }
}
