import { Controller } from '@hotwired/stimulus'

export default class ShelterTestSuiteController extends Controller {
  static targets = ['loader', 'content'];

  showSpinLoader (e) {
    if (this.hasLoaderTarget) {
      this.loaderTarget.classList.remove('hidden')
      this.contentTarget.classList.add('hidden')
    }
  }
}
