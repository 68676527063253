import { Controller } from '@hotwired/stimulus'

export default class FlashController extends Controller {
  closeFlash (e) {
    this.element?.remove()
  }

  renderFlash (messageContent, type) {
    if (messageContent === '' || type === '') return

    const flashContainer = document.getElementById('flash-messages-container')

    if (flashContainer) {
      const flashElement = `
        <div data-controller="flash" class="alert alert-${type}" role="alert">
          <div class="alert-header">
            <div class="flex items-center mr-2">
              <h6 class="alert-header-title">${type.charAt(0).toUpperCase() + type.slice(1)}</h6>
            </div>
            <button type="button" data-action="click->flash#closeFlash">
              <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" class="close-icon" viewBox="0 0 20 20">
                <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path>
              </svg>
            </button>
          </div>
          <div class="alert-description">
            ${messageContent}
          </div>
        </div>`
      flashContainer.innerHTML = flashElement
    }
  }
}
