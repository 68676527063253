import { Html5Qrcode } from 'html5-qrcode'
import { Controller } from '@hotwired/stimulus'

export default class QrScannerController extends Controller {
  connect () {
    this.modalTarget = document.getElementById('qrScannerModal')
    if (!this.modalTarget) throw new Error('QR scanner modal target not found')

    this.modalCloseButtonTarget = this.modalTarget.querySelector('.modal-close-icon')
    this.modalHeaderTarget = this.modalTarget.querySelector('.modal-header')

    if (!this.modalCloseButtonTarget) throw new Error('Modal close button target not found')
    if (!this.modalHeaderTarget) throw new Error('Modal header target not found')

    this.clickHandler = () => this.closeQrScanner()
    this.modalCloseButtonTarget.addEventListener('click', this.clickHandler)
  }

  disconnect () {
    if (this.modalCloseButtonTarget && this.clickHandler) {
      this.modalCloseButtonTarget.removeEventListener('click', this.clickHandler)
    }
  }

  handleFiledFocusOut (e) {
    const { id } = e.currentTarget
    let value = e.target.value

    if (value && id.includes('gocodes')) {
      value = value.trim()?.split('/').pop().replace(/-/g, '')
    } else if (value && id.includes('mac_address')) {
      value = value.replace(/[-:]/g, '').toUpperCase().match(/.{1,2}/g).join(':')
    }

    e.target.value = value
  }

  closeQrScanner () {
    this.qrCodeReader?.stop()
    this.stream?.getTracks().forEach((track) => {
      this.modalTarget.classList.add('hidden')
      track.stop()
    })
  }

  qrboxFunction (viewfinderWidth, viewfinderHeight) {
    const minEdgePercentage = 0.9
    const minEdgeSize = Math.min(viewfinderWidth, viewfinderHeight)
    const qrboxSize = Math.floor(minEdgeSize * minEdgePercentage)
    return {
      width: qrboxSize,
      height: qrboxSize
    }
  }

  handleOpenQrCodeScanner (event) {
    const { inputId, inputTitle, attributeName } = event.params
    this.modalHeaderTarget.innerHTML = `Scan ${inputTitle} of ${attributeName}`

    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        this.modalTarget.classList.remove('hidden')
        this.stream = stream
        this.qrCodeReader = new Html5Qrcode('qrScannerModalCameraReader')
        this.qrCodeReader.start(
          { facingMode: 'environment' },
          {
            fps: 10,
            qrbox: this.qrboxFunction
          },
          (qrCodeMessage) => {
            const inputField = document.getElementById(inputId)
            inputField.value = inputField.id.includes('gocodes')
              ? qrCodeMessage.split('/').pop()
              : qrCodeMessage

            // eslint-disable-next-line
            inputField.dispatchEvent(new Event('change'))
            this.modalCloseButtonTarget.click()
            this.closeQrScanner()
          }
        )
      })
      .catch(() => {
        this.modalTarget.classList.add('hidden')
        window.alert(
          `To use this feature, please grant permission to access your camera.\n\n
          1. Click on the camera icon in the browser's address bar.
          2. In the dropdown, select 'Site settings' or 'Permissions'.
          3. Set 'Camera' permissions to 'Allow' or 'Ask'.
          4. Reload the page to apply the changes.`
        )
      })
  }
}
