import { Controller } from '@hotwired/stimulus'

export default class PduControlsController extends Controller {
  static targets = ['content', 'loader']

  showLoader (event) {
    const confirmMessage = event.target.dataset.confirmMessage || 'Are you sure you want to Reboot/ Turn On PDU outlet?'
    const userConfirmed = window.confirm(confirmMessage)

    if (userConfirmed) {
      console.log('Showing Loader')
      this.loaderTarget.classList.remove('hidden')
      this.contentTarget.classList.add('hidden')
    } else {
      event.preventDefault()
    }
  }
}
