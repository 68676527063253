import { Controller } from '@hotwired/stimulus'

export default class ReportController extends Controller {
  static targets = [
    'fileContainer',
    'fileDetailContainer',
    'urlContainer',
    'fileField',
    'selectField',
    'bulkUploadField'
  ]

  static values = {
    isBulkUpload: Boolean
  }

  connect () {
    if (!this.isBulkUploadValue) {
      this.fileDetailContainerTarget.innerText = ''
      this.updateAttachmentField()
    }
  }

  updateAttachmentField () {
    if (this.selectFieldTarget.value === 'url') {
      this.fileContainerTarget.classList.add('hidden')
      this.urlContainerTarget.classList.remove('hidden')
    } else if (this.selectFieldTarget.value === 'upload') {
      this.urlContainerTarget.classList.add('hidden')
      this.fileContainerTarget.classList.remove('hidden')
    } else {
      this.urlContainerTarget.classList.add('hidden')
      this.fileContainerTarget.classList.add('hidden')
    }
  }

  handleFileUpload ({ target }) {
    if (this.isBulkUploadValue) {
      this.fileDetailContainerTarget.innerText = `${target.files.length} files uploaded`
    } else {
      this.fileDetailContainerTarget.innerText = target.files[0].name
    }
  }

  openFolderBrowseWindow () {
    this.bulkUploadFieldTarget.click()
  }
}
