// Entry point for the build script in your package.json
import './controllers'
import '@hotwired/turbo-rails'
import 'flowbite/dist/flowbite.turbo'
import jQuery from 'jquery'
import LoadFancySelects from './utils/load_fancy_selects'
import { TimeParser, PeriodSelector, Pagination, DropdownPeriodSelector } from 'tranzito_utils_js'

document.addEventListener('turbo:load', () => {
  // Period select
  if (document.getElementById('timeSelectionBtnGroup')) {
    const periodSelector = new PeriodSelector()
    periodSelector.init()
  } else if (document.getElementById('periodSelectDropdown')) {
    const dropdownPeriodSelector = new DropdownPeriodSelector()
    dropdownPeriodSelector.init()
  }

  if (!window.timeParser) window.timeParser = new TimeParser()
  window.timeParser.localize()

  window.pagination = new Pagination()
  window.pagination.init()

  LoadFancySelects()
})

document.addEventListener('turbo:frame-render', () => {
  const perPageSelect = document.querySelector('#per_page_select')

  if (perPageSelect) {
    perPageSelect.addEventListener('change', (event) => {
      const turboFrameTag = event.currentTarget.closest('turbo-frame')
      if (!turboFrameTag) return

      const url = new URL(turboFrameTag.src)
      const urlParams = new URLSearchParams(url.search)
      urlParams.set('per_page', event.currentTarget.value)
      turboFrameTag.src = `${url.pathname}?${urlParams.toString()}`
    })
  }

  LoadFancySelects()
})

window.$ = window.jQuery = jQuery
