import { Controller } from '@hotwired/stimulus'

export default class TabsController extends Controller {
  static targets = ['tab', 'tabContent'];

  connect () {
    const currentTab = new URLSearchParams(window.location.search).get('current_tab')

    if (currentTab === null) {
      this.activateTab(this.tabTargets[0])
    } else {
      const matchingTab = this.tabTargets.find(tab => tab.dataset.pathName === currentTab)
      if (matchingTab) {
        this.activateTab(matchingTab)
      }
    }
  }

  changeTab (event) {
    const tabId = event.target.dataset.tab
    const selectedTab = this.tabTargets.find(tab => tab.dataset.tab === tabId)
    this.updateUrlParams(selectedTab.dataset.pathName)
    this.activateTab(selectedTab)
  }

  activateTab (tab) {
    const tabContentId = tab.dataset.tab

    this.tabContentTargets.forEach(tabContent => tabContent.classList.add('hidden'))
    document.getElementById(tabContentId).classList.remove('hidden')

    this.tabTargets.forEach(btn => btn.classList.remove('active'))
    tab.classList.add('active')
  }

  updateUrlParams (currentTabName) {
    const urlSearchParams = new URLSearchParams(window.location.search)
    urlSearchParams.set('current_tab', currentTabName)
    window.history.replaceState({}, '', `${window.location.pathname}?${urlSearchParams}`)
  }
}
